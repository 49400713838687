<template>
  <div class="view pa24">
    <div>
      <el-select v-model="value" value-key placeholder="请选择" @change="optionChange">
        <el-option
          v-for="(item,index) in options"
          :key="index"
          :label="item.value"
          :value="item.lable"
        >
        </el-option>
      </el-select>
      <!-- <el-button class="ml10" type="primary">导出</el-button> -->
    </div>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column
          type="index"
          align="center"
          label="编号"
          show-overflow-tooltip
        />
        <el-table-column prop="name" align="center" label="姓名 " />
        <el-table-column prop="phone" align="center" label="手机号" />
        <el-table-column prop="clockDay" align="center" label="累计打卡天数" />
        <el-table-column prop="firstTime" align="center" label="初次打卡时间" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="detail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  queryPageUserClock
} from "@/api/addedServices";

export default {
  data() {
    return {
      value:'',
      options:[
        {lable:'1',value:'累计打卡天数从高到低'},
        {lable:'2',value:'累计打卡天数从低到高'},
      ],
      tableData: [],
      loading:false,
      currentPage: 1, //当前页数
      total: 0, //总条数
      pageNum:1,
      pageSize:10,
      dayNum:1
    };
  },
  components: {
    commonTable,
  },
  created(){
    this.getTableData()
  },
  methods:{
    optionChange(val){
      this.dayNum =  val
      this.getTableData()
    },
    async getTableData() {
      this.loading = true
      let data = {
        pageNum: this.pageNum,
        pageSize:this.pageSize,
        type : this.dayNum
      };
      const result = await queryPageUserClock(data);
      this.loading = false
      if(result.data){
        this.tableData = result.data.pageInfo.list;
        this.total = result.data.pageInfo.total
        this.tableData.forEach(v=>{
          v.imagesList = [];
          v.imagesList.push(v.logo);
        })
      }else{
        this.tableData = []
      }
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    detail(row){
      this.$router.push({ path:`/clockDataDetall?clockId=${row.clockId}` });
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
  }
};
</script>

<style lang="scss" scoped>
</style>